import React, { Component } from "react"
import SEO from "../components/seo"
import HeaderDark from "../components/header/header-dark"
import { Global, css } from "@emotion/core"

class ContactPage extends Component {
  expandColumn(e) {
    document.querySelectorAll(".column").forEach(element => {
      element.classList.remove("open")
    })
    e.target.classList.toggle("open")
  }

  formSubmit(e) {
    e.preventDefault()
    const name = document.querySelector("#name").value
    const email = document.querySelector("#email").value
    const phone = document.querySelector("#phone").value
    const msg = document.querySelector("#msg").value
    fetch(e.target.action, {
      method: "POST",
      body: JSON.stringify({
        name,
        email,
        phone,
        msg,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(res => res.json())
      .then(() => {
        const alert = document.querySelector("#response")
        alert.style.opacity = 1
        alert.style.pointerEvents = "all"
        alert.querySelector("p").innerText =
          "Thank you for submitting our form! Someone from our office will be in touch shortly."
        document.querySelector("#name").value = ""
        document.querySelector("#email").value = ""
        document.querySelector("#phone").value = ""
        document.querySelector("#msg").value = ""
        setTimeout(() => {
          document.querySelector("#response").style.opacity = 0
          document.querySelector("#response").style.pointerEvents = "none"
        }, 5000)
      })
      .catch(() => {
        const alert = document.querySelector("#response")
        alert.style.opacity = 0
        alert.style.pointerEvents = "none"
        alert.querySelector("p").innerText =
          "There was an error submitting the form. Please try again."
        setTimeout(() => {
          document.querySelector("#response").style.opacity = 0
          document.querySelector("#response").style.pointerEvents = "none"
        }, 5000)
      })
  }

  closeAlert() {
    document.querySelector("#response").style.opacity = 0
    document.querySelector("#response").style.pointerEvents = "none"
  }

  render() {
    return (
      <>
        <SEO title="Contact" />
        <HeaderDark />
        <Global
          styles={css`
            body {
              background-color: #eff0f2;
            }
          `}
        />
        <div
          css={css`
            position: fixed;
            left: 0;
            right: 0;
            top: 25%;
            width: 50vw;
            height: 50vh;
            min-width: 375px;
            min-height: 375px;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background: #1e1e1e;
            z-index: 9999;
            color: #fefefe;
            font-family: "Montserrat", sans-serif;
            font-weight: 700;
            font-size: 1.5rem;
            opacity: 0;
            pointer-events: none;

            p {
              text-align: center;
              max-width: 75%;
              margin: 0 auto;
            }

            button {
              position: absolute;
              right: 1rem;
              top: 1rem;
              background: none;
              color: #fefefe;
              border: none;

              &:hover {
                cursor: pointer;
                opacity: 0.5;
              }
            }
          `}
          id="response"
        >
          <p></p>
          <button onClick={this.closeAlert}>&times;</button>
        </div>
        <section
          css={css`
            width: 100vw;
            height: 100vh;
            position: relative;
            top: 4rem;

            @media (min-width: 1024px) {
              height: auto;
              top: 0;
            }
          `}
        >
          <h1
            css={css`
              margin: 15vh 0;

              .text {
                font-family: "Montserrat", sans-serif;
                font-weight: 700;
                transform: translate(1, 0.9);
                text-transform: lowercase;
                color: #0a0a0a;
                text-align: center;
                margin: 0;
                white-space: nowrap;
              }

              .line-1 {
                font-size: 1.25rem;
              }

              .line-2,
              .line-3 {
                font-size: 9vw;
                line-height: 1ch;

                @media (min-width: 1024px) {
                  font-size: 5rem;
                }

                @media (min-width: 1440px) {
                  font-size: 6rem;
                }
              }

              .line-3 {
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-color: #0a0a0a;
                -webkit-text-stroke-width: 1px;
              }
            `}
          >
            <p className="text line-1">digital marketing for lawyers</p>
            <p className="text line-2">we generate leads</p>
            <p className="text line-3">you practice law</p>
          </h1>
          <section
            css={css`
              display: flex;
              flex-direction: column-reverse;

              @media (min-width: 1024px) {
                flex-direction: row;
                padding: 0 8rem;
              }

              & > * {
                width: calc(100% - 2rem);
                margin: 0 auto 2rem;

                @media (min-width: 1024px) {
                  margin: 0;
                  width: calc(100% - 8rem);
                }
              }

              & > div {
                @media (min-width: 1024px) {
                  width: 33%;
                }
              }

              & > form {
                @media (min-width: 1024px) {
                  width: 67%;
                }
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
              `}
            >
              <div
                css={css`
                  transform: scale(1, 0.9);

                  .title {
                    font-family: "Montserrat", sans-serif;
                    font-weight: 700;
                    font-size: 0.875rem;
                    text-transform: uppercase;
                    color: #2497c6;
                    position: relative;
                    letter-spacing: 3px;

                    &:after {
                      position: absolute;
                      left: 0;
                      top: 50%;
                      width: 3rem;
                      height: 1px;
                      background-color: #2497c6;
                      display: block;
                      content: "";
                      transform: translateX(calc(-100% - 1rem));
                    }
                  }

                  .address {
                    font-family: "Montserrat", sans-serif;
                    font-size: 1.25rem;
                    margin: 0;
                  }

                  .tel {
                    font-family: "Montserrat", sans-serif;
                    font-weight: 700;
                    font-size: 1.5rem;
                    margin-top: 1rem;
                    color: #1e1e1e;
                    display: block;
                    text-decoration: none;
                  }
                `}
              >
                <p className="title">Location</p>
                <p className="address">109 west main street</p>
                <p className="address">moorestown, new jersey</p>
                <a className="tel" href="+tel:18665321187">
                  866.532.1187
                </a>
              </div>
            </div>
            <form
              action="https://usebasin.com/f/8b46b46384b0"
              method="post"
              onSubmit={this.formSubmit}
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;

                .row {
                  margin: 0 0 2rem;
                  display: flex;
                  flex-direction: column;

                  input:not([type="submit"]),
                  textarea {
                    height: 3rem;
                    border: none;
                    background: #fefefe;
                    border-bottom: 2px solid #1e1e1e;
                    margin-bottom: 0.5rem;
                  }

                  textarea {
                    width: 100%;
                    height: 8rem;
                    margin-bottom: 0.5rem;
                  }

                  label {
                    font-family: "Montserrat", sans-serif;
                    transform: scale(1, 0.9);
                    font-weight: 700;
                  }
                }

                .row:nth-of-type(-n + 3) {
                  width: 100%;

                  @media (min-width: 1024px) {
                    width: calc(100% / 3 - 1rem);
                  }
                }

                .row:nth-of-type(n + 4) {
                  width: 100%;
                }

                .row:nth-of-type(5) {
                  align-items: flex-end;
                }
              `}
            >
              <div className="row">
                <input type="text" name="Name" id="name" required />
                <label htmlFor="name">name *</label>
              </div>
              <div className="row">
                <input type="email" name="Email" id="email" required />
                <label htmlFor="email">email *</label>
              </div>
              <div className="row">
                <input type="tel" name="Phone" id="phone" />
                <label htmlFor="phone">phone</label>
              </div>
              <div className="row">
                <textarea name="Message" id="msg" required></textarea>
                <label htmlFor="msg">how can we help? *</label>
              </div>
              <div className="row">
                <input type="hidden" name="_gotcha" />
                <input
                  type="submit"
                  value="Contact a rep today"
                  css={css`
                    color: #fefefe;
                    font-family: "Montserrat", sans-serif;
                    transform: scale(1, 0.9);
                    font-weight: 700;
                    font-size: 12px;
                    text-transform: uppercase;
                    letter-spacing: 3px;
                    text-decoration: none;
                    transition: all 0.4s ease-in-out;
                    background-color: #115e7d;
                    padding: 1.25rem 3rem;
                    border: none;

                    :hover {
                      cursor: pointer;
                      color: #2497c6;
                      background-color: #fefefe;
                    }
                  `}
                />
              </div>
            </form>
          </section>
        </section>
      </>
    )
  }
}

export default ContactPage
